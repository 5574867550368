import React from 'react';
import { array, bool, object, string } from 'prop-types';
import Thumb from '@/components/thumb/Thumb';
import WidgetTitle from '@/components/widgets/WidgetTitle';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import WidgetAuthor from '@/components/widgets/WidgetAuthor';
import WidgetDate from '@/components/widgets/WidgetDate';
import Slider from '@/components/slider/Slider';
import {
	getDataType,
	getWidgetCategory,
	getThemeClassName,
	getContentListFromGalleryFolders
} from '@/components/widgets/widgetHelpers';
import { thumbType, thumbWidth, thumbWidth2x } from './constants';
import SnipeBanner from '@/components/snipe-banner/SnipeBanner';
import './Carousel.scss';

/**
 * @function Carousel
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {Array} props.contentList
 * @param {Object} [props.criteria]
 * @param {String} [props.header]
 * @param {Object} [props.headerImage]
 * @param {Boolean} [props.isCurated]
 * @param {String} [props.position]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String} [props.widgetTemplate]
 * @param {String} [props.pageTheme]
 * @return {React.ReactElement}
 */
const Carousel = ({
	authorBylineEnabled,
	contentList,
	criteria = {},
	header,
	headerImage,
	isCurated,
	position,
	timestampsEnabled,
	widgetTemplate = '',
	pageTheme
}) => {
	const updatedContentList = getContentListFromGalleryFolders(contentList);

	return updatedContentList?.length > 0 ? (
		<section
			className={`widget widget__carousel ${getThemeClassName(pageTheme)}`}
			data-hook="widget-carousel"
			data-tb-region={`widget__carousel ${widgetTemplate} ${position}`}
		>
			<WidgetHeader title={header} thumbnail={headerImage} />
			<Slider>
				{updatedContentList.map(
					({ displayTitle, displayImage, reference, linkType }, index) => {
						if (!reference || !displayImage) return null;

						const {
							branding,
							id,
							uri,
							authors,
							publishDate,
							snipe,
							firstPublishDate
						} = reference;
						const analyticsData = {
							'data-widget-type': 'carousel-widget',
							'data-widget-template': widgetTemplate,
							'data-widget-position': position,
							'data-widget-data-type': getDataType(criteria, isCurated),
							'data-widget-category': getWidgetCategory(criteria, isCurated)
						};

						return (
							<div
								key={id}
								className="carousel__slide"
								data-hook="carousel-slide"
								data-tb-region-item
							>
								<div className="widget__thumb">
									<Thumb
										analyticsData={analyticsData}
										href={uri}
										thumbnail={displayImage}
										videoId={linkType === 'VIDEO' ? id : null}
										type={thumbType}
										width={thumbWidth}
										width2x={thumbWidth2x}
									>
										{snipe ? (
											<SnipeBanner
												snipe={snipe}
												color="WHITE"
												firstPublishDate={firstPublishDate}
											/>
										) : null}
										<ContentItemIcon type={linkType} />
									</Thumb>
								</div>
								<WidgetTitle
									title={displayTitle}
									href={uri}
									analyticsData={analyticsData}
								/>
								{isCurated && branding && <SponsoredBy {...branding} />}
								{authorBylineEnabled && authors && (
									<WidgetAuthor authors={authors} />
								)}
								{timestampsEnabled && publishDate && (
									<WidgetDate date={publishDate} />
								)}
							</div>
						);
					}
				)}
			</Slider>
		</section>
	) : null;
};

Carousel.propTypes = {
	contentList: array.isRequired,
	criteria: object,
	header: string,
	headerImage: object,
	isCurated: bool,
	position: string,
	widgetTemplate: string,
	timestampsEnabled: bool,
	authorBylineEnabled: bool,
	pageTheme: string
};

Carousel.displayName = 'Carousel';

export default Carousel;
